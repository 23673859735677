<template>
  <!-- BEGIN: Content-->
  <div class="chat-application">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">Patient Register</a>
        <div>
          <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fa-solid fa-file-import"></i>
          </button>
          <button @click="handleCreate" class="navbar-toggler border-0" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fa fa-plus-circle"></i>
          </button>
        </div>

        <div class="profile-head d-flex">
          <div class="patient-search mr-5">
              <input
                  name="serial"
                  type="number"
                  v-model="patientSerialNo"
                  class="form-control"
                  placeholder="Patient Id"
              />
          </div>

          <div class="single-search-action">
              <button 
                  class="btn btn-primary"
                  @click="searchPatientById"
              >
                  Go
              </button>
          </div>
        </div>

        <div class="d-none d-md-block d-lg-block collapse justify-content-end navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav h4">
            <li class="nav-item me-2">
              <a class="border-r" href="#"><i class="fa-solid fa-file-import"></i> Import</a>
            </li>
            <li @click="handleCreate" class="nav-item">
              <a class="border-r" href="#"><i class="fa fa-plus-circle"></i> Create</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="content-area-wrapper container-xxl p-0">

      <div class="sidebar-left">
        <div class="sidebar">

          <!-- Contact Sidebar area -->
          <div class="sidebar-content" :class="{ 'show': sidebarStatus }">
            <span @click="sidebarActiveInactive" class="sidebar-close-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18"
                                                                                                y2="18"></line></svg>
            </span>
            <!-- Sidebar header start -->
            <div class="chat-fixed-search">
              <div class="d-flex align-items-center w-100">
                <div class="fw-bold h4 sidebar-profile-toggle">
                  Filter
                </div>
                <div class="input-group input-group-merge ms-1 w-100">
                  <span class="input-group-text round"><i data-feather="search" class="text-muted"></i></span>
                  <input v-model="searchTerm" type="text" class="form-control round"
                         id="chat-search"
                         placeholder="Quick Search.." aria-label="Search..."
                         aria-describedby="chat-search"/>
                </div>
              </div>
            </div>
            <!-- Sidebar header end -->

            <!-- Sidebar Contacts start -->
            <Sidebar
                :contacts="contacts"
                :selectedContactId="selectedContactId"
                @selectContact="selectContact"
            >
              <template #paginate>
                <paginate
                  :page-count="totalPage"
                  :click-handler="onChangeHandler"
                  :prev-text="'Prev'"
                  :next-text="'Next'"
                />
              </template>
            </Sidebar>
            <!-- Sidebar Contacts end -->
          </div>
          <!--/ Contact Sidebar area -->
        </div>
      </div>
      <Contents
          :contactInfo="contactInfo"
          :contactDetails="contactDetails"
          @handleEdit="handleEdit"
          @sidebarActiveInactive="sidebarActiveInactive"
      />
    </div>
  </div>
  <!-- END: Content-->
  <GlobalLoader v-if="loader"/>
</template>

<script setup>
import Sidebar from '@/components/molecule/company/hospital/patient/register/Sidebar.vue'
import Contents from '@/components/molecule/company/hospital/patient/register/Contents.vue'
import {computed, inject, onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import handleCompanyContact from "@/services/modules/companyContact";
import Paginate from "vuejs-paginate-next";

const router = useRouter()
const route = useRoute()
const store = useStore();
const {fetchCompanyAllContacts, fetchContactDetails} = handleCompanyContact();
const showError = inject('showError');
const showSuccess = inject('showSuccess');

const loader = ref(false)
const sidebarStatus = ref(false)
const contacts = ref([])
const selectedContactId = ref('')
const contactDetails = ref([])
const contactInfo = ref({})
const searchTerm = ref('');
const patientSerialNo = ref(null);

let debounceTimer = null;

const companyId = computed(() => route.params.companyId);

const offset = ref(10);
const page = ref(1);
const totalPage = ref(1);

const onChangeHandler = (pageNumber) => {
  page.value = pageNumber
  getContactsList();
}

const getQuery = () => {
  let query = '?company_id=' + companyId.value
  query += '&offset=' + offset.value
  query += "&roles=patient"
  if (page.value) query += '&page=' + page.value;
  if (searchTerm) query += '&q=' + searchTerm.value
  if (patientSerialNo.value) query += `&serial_no=${patientSerialNo.value}`;
  return query
}

const searchPatientById = async () => {
  if(!patientSerialNo.value) return;
  await getContactsList();
}

const handleCreate = () => {
  router.push({name: 'add-update-patient'})
};

const handleEdit = (contact) => {
  let routeQuery = Object.assign({}, route.query);
  routeQuery.type = contact.contact_type
  delete routeQuery.page
  router.push({name: 'add-update-patient', query: routeQuery});
}

const sidebarActiveInactive = () => {
  sidebarStatus.value = !sidebarStatus.value
}

const setPagination = (data) => {
  const totalPageNumber = parseInt(data.total/offset.value);
  const fraction = data.total % offset.value;
  if(fraction !== 0) {
    totalPage.value = totalPageNumber + 1;
    return;
  }

  totalPage.value = totalPageNumber
};

const resetPagination = (data) => {
  page.value = 1
  totalPage.value = 1
};

async function getContactsList() {
  try {
    const res = await fetchCompanyAllContacts(getQuery());
    if (!res.status) {
      contacts.value = [];
      return resetPagination()
    }
    contacts.value = res.data.data;
    setPagination(res.data)
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

watch(searchTerm, (newValue) => {
  if (debounceTimer) {
    clearTimeout(debounceTimer);
  }

  debounceTimer = setTimeout(() => {
    getContactsList();
  }, 500);
});


const selectContact = (contactId) => {
  const isMobile = window.innerWidth < 768;
  if (isMobile) {
    sidebarActiveInactive()
  }

  let routeQuery = Object.assign({}, route.query)
  selectedContactId.value = contactId
  routeQuery.contactId = selectedContactId.value
  getContactDetails(selectedContactId.value)
  router.push({name: 'patient-register', query: routeQuery})
}

const getContactDetails = async (contactId, type = 'activities') => {
  try {
    const res = await fetchContactDetails(type, contactId, '?company_id=' + companyId.value);
    if (!res.status) {
      return contactDetails.value = []
    }
    contactInfo.value = res.data.contact_profile;
    contactDetails.value = res.data.contact_details;
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

onMounted(async () => {
  await getContactsList()
  if (!route.query.contactId && contacts.value.length) {
    selectContact(contacts.value[0].id)
  }
  if (route.query.contactId) {
    selectedContactId.value = route.query.contactId
    await getContactDetails(selectedContactId.value)
  }
});
</script>


<style scoped lang="scss">
.chat-application {
  .sidebar-content, .chat-fixed-search {
    width: 300px !important;
  }

  li.nav-item {
    a {
      font-size: 22px;
    }
  }

  button.navbar-toggler {
    font-size: 33px;
    color: #8482e5
  }
}
</style>
